body {
  margin: 0;
  font-family: 'Roboto Mono', monospace;
}

html {
  scroll-behavior: smooth;
}



@tailwind base;
@tailwind components;
@tailwind utilities;